.userItem {
  border: 1px solid grey;

  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-top: 8px;
  width: 100%;

  &:hover {
    background-color: lightgrey;
    cursor: pointer;
    box-shadow: 0 0 0 1px yellow;
  }
}
