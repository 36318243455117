.usersList {
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.usersList ul {
  width: 100%;
  padding: 0;
}
